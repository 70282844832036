import { request } from 'core/request';

const checkUserRegisteredPasswordless = async (userId, loginId) => {
  const data = await request({
    method: 'post',
    url: `/dashboard/fido/checkCredential`,
    data: {
      userId: userId,
      loginId: loginId
    }
  });
  return data;
};

const deleteUserRegisteredPasswordless = async (userId, loginId) => {
  const data = await request({
    method: 'delete',
    url: `/dashboard/fido/deleteCredential`,
    data: {
      userId: userId,
      loginId: loginId
    }
  });
  return data;
};

const writeAuthAppErrorLog = async (
  timeStamp,
  loginId,
  deviceId,
  deviceModel,
  osVersion,
  appVersion,
  level,
  message,
  errLog
) => {
  appVersion = String(appVersion);
  const data = await request({
    method: 'post',
    url: `/dashboard/fido/writeAuthAppErrorLog`,
    data: {
      timeStamp,
      loginId,
      deviceId,
      level,
      appKey: 'com.transcosmos.dev.tpassport.auth',
      deviceModel,
      osVersion,
      appVersion,
      message,
      errLog
    }
  });
  return data;
};

export default {
  checkUserRegisteredPasswordless,
  deleteUserRegisteredPasswordless,
  writeAuthAppErrorLog
};
