import { request } from 'core/request';
import {
  DOMAIN_TRANSCOSMOS,
  MODE_LOGIN,
  COOKIE_KEY_USER_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  COOKIE_KEY_REFRESH_TOKEN,
  COOKIE_KEY_USER_TOKEN_EXP,
  COOKIE_KEY_REFRESH_TOKEN_EXP
} from 'core/constants';
import store from 'store';
import { getModeLogin } from 'store/globalSlice';
import { cookies } from 'core/utils';
import { encodeBase64 } from 'core/helpers';

const setCookies = data => {
  let {
    code = '',
    userToken = '',
    refreshToken = '',
    atmToken = '',
    atmTokenExpiresAt = '',
    userTokenExp = 0,
    refreshTokenExp = 0
  } = data || {};
  if (code !== 'otp-required') {
    if (userToken) {
      cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
      cookies.setKey(COOKIE_KEY_USER_TOKEN_EXP, userTokenExp);
      cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken);
      cookies.setKey(COOKIE_KEY_REFRESH_TOKEN_EXP, refreshTokenExp);
    }
    if (atmToken) {
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, atmToken);
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
    }
  }
};

const getFullEmail = loginId => {
  const state = store.getState();
  const modeLogin = getModeLogin(state);
  let email = loginId;
  if (MODE_LOGIN.LOGIN_BY_LOGIN_ID === modeLogin && !loginId.includes('@')) email = `${loginId}@${DOMAIN_TRANSCOSMOS}`;
  return email;
};

const authenticate = async ({
  email: loginId,
  password,
  token = '',
  resourceId = '',
  authCode,
  sessionQR,
  provider,
  state
}) => {
  loginId = sessionQR && sessionQR.loginId ? getFullEmail(sessionQR.loginId) : getFullEmail(loginId);
  // get system language
  const lng = localStorage.getItem('sysLanguage') || 'en_US';
  let data = {
    loginId,
    password,
    lng
  };
  if (token) {
    data = {
      token,
      provider,
      lng
    };
  } else if (authCode) {
    data = {
      authCode,
      provider,
      state,
      lng
    };
  }

  if (resourceId) {
    data = { ...data, resourceId };
  }
  if (sessionQR && sessionQR.sessionId) {
    data = { ...data, sessionQR };
  }
  const res = await request(
    {
      method: 'post',
      url: '/dashboard/auth/login',
      data: data,
      headers: {
        'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    false,
    false
  );
  setCookies(res.data);
  return res.data;
};

const verifyOTP = async (loginId, mfaMethod, otp, mustGenerateToken = false) => {
  loginId = getFullEmail(loginId);
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/verifyOTP',
      data: {
        loginId,
        verifyMethod: mfaMethod,
        otp,
        mustGenerateToken
      }
    },
    false,
    false
  );
  return data;
};

const resendOTP = async (loginId, resourceId = '', sendingOTPMethod) => {
  loginId = getFullEmail(loginId);
  let data = {
    loginId,
    lng: localStorage.getItem('sysLanguage') || 'en_US',
    sendingOTPMethod
  };
  if (resourceId) {
    data.resourceId = resourceId;
  }
  const result = await request(
    {
      method: 'post',
      url: '/dashboard/auth/resendOTP',
      data: data
    },
    false,
    false
  );
  return result;
};

const verifyOTPLogin = async (loginId, otp) => {
  loginId = getFullEmail(loginId);
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/verifyOTPLogin',
      data: {
        loginId,
        otp
      }
    },
    false,
    false
  );
  setCookies(data);
  return data;
};

const renewSecurityToken = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/renewSecurityToken'
    },
    true,
    false
  );
  return data;
};

const logout = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/logout'
    },
    true,
    true
  );
  return data;
};

const logoutAllSessions = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/logoutAllSessions'
    },
    true,
    true
  );
  return data;
};

const refreshToken = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/refreshToken'
    },
    false,
    true
  );
  return data;
};

const redirectAuth = async queryStr => {
  const data = await request(
    {
      method: 'get',
      url: '/dashboard/authorize' + queryStr
    },
    false,
    false
  );
  return data;
};

const verifyCode = async (authCode, state) => {
  const data = await request(
    {
      method: 'post',
      url: '/saml/code',
      data: { authCode, state }
    },
    false,
    false
  );

  return data;
};

const getQRCode = async () => {
  const data = await request({ method: 'get', url: '/dashboard/fido/getQRCode' }, false, false);
  return data;
};

const getAppDownloadLink = async () => {
  const data = await request({ method: 'get', url: '/dashboard/fido/generateQRCodeInstallApp' }, false, false);
  return data;
};

const getStatusQRCode = async sessionId => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/fido/checkQRCodeAfterAppScan',
      data: { sessionId }
    },
    false,
    false
  );

  return data;
};

const signInWithMicrosoft = async redirectURI => {
  let url = '/dashboard/auth/microsoft';
  if (redirectURI) url += `?redirect=${encodeBase64(redirectURI)}`;
  return request(
    {
      method: 'get',
      url
    },
    false,
    false
  );
};

const verifyPresignedURL = async token => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/verifyPresignedURL',
      data: {
        token
      }
    },
    false,
    false
  );
  return data;
};

export default {
  authenticate,
  refreshToken,
  logout,
  verifyOTP,
  resendOTP,
  verifyOTPLogin,
  renewSecurityToken,
  redirectAuth,
  verifyCode,
  logoutAllSessions,
  getStatusQRCode,
  getQRCode,
  getAppDownloadLink,
  signInWithMicrosoft,
  verifyPresignedURL
};
