import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Profile from './forms/Profile';
import Password from './forms/Password';
import TwoStepAuthentication from './forms/TwoStepAuthentication';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from 'components/ModalBox';
import { Loading } from 'element-react';
import PasswordlessSetting from './forms/Passwordless';
import PasswordlessRegistration from './forms/PasswordlessRegistration';
import { checkIsMobile } from 'core/helpers';

function ProfileSettings(props) {
  const { t } = useTranslation();
  const { notification, fullloading, currentUser } = props;
  const isMobile = checkIsMobile();
  const initialTab = isMobile ? '4' : '1';

  const [activeTab, setActiveTab] = useState(initialTab);
  const displayTabs = currentUser && currentUser.loginDomainProvider === 'email';
  const action = localStorage.getItem('action');
  if (!checkIsMobile() && 'SETTING_MFA' === action) {
    setActiveTab('3');
    localStorage.removeItem('action');
  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        {checkIsMobile() ? (
          <Nav tabs className="nav-tabs-simple reponsive-tab" style={{ display: 'flex', justifyContent: 'center' }}>
            <NavItem style={{ width: '100%' }}>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggle('4');
                }}
                style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}
              >
                {t('common.passwordless.label')}
              </NavLink>
            </NavItem>
          </Nav>
        ) : (
          <Nav tabs className="nav-tabs-simple reponsive-tab">
            {!checkIsMobile() && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  {t('common.profile.label')}
                </NavLink>
              </NavItem>
            )}
            {!checkIsMobile() && displayTabs && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  {t('common.password.label')}
                </NavLink>
              </NavItem>
            )}
            {!checkIsMobile() && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggle('3');
                  }}
                >
                  {t('common.twoStep.auth.mfa.label')}
                </NavLink>
              </NavItem>
            )}
            {currentUser?.isEnablePasswordless && currentUser?.isEnablePasswordlessInDomain && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => {
                    toggle('4');
                  }}
                >
                  {t('common.passwordless.label')}
                </NavLink>
              </NavItem>
            )}
          </Nav>
        )}
      </div>
      <div className="card-body">
        <TabContent activeTab={activeTab} className="m-p-lr-0 p-r-20 p-l-20">
          <TabPane tabId="1">{!checkIsMobile() && <Profile />}</TabPane>
          <TabPane tabId="2">{!checkIsMobile() && <Password />}</TabPane>
          <TabPane tabId="3">{!checkIsMobile() && <TwoStepAuthentication />}</TabPane>
          <TabPane tabId="4">
            {checkIsMobile() ? (
              <PasswordlessRegistration user={currentUser} />
            ) : currentUser?.isEnablePasswordless && currentUser?.isEnablePasswordlessInDomain ? (
              <PasswordlessSetting />
            ) : (
              ''
            )}
          </TabPane>
        </TabContent>
      </div>
      {notification.showModal && <ModalBox />}
      {fullloading && <Loading fullscreen={true} />}
    </div>
  );
}

export default ProfileSettings;
