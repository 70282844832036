import { request } from 'core/request';
import { DOMAIN_TRANSCOSMOS, MODE_LOGIN } from 'core/constants';
import store from 'store';
import { getModeLogin } from 'store/globalSlice';

const getFullEmail = loginId => {
  const state = store.getState();
  const modeLogin = getModeLogin(state);
  let email = loginId;
  if (MODE_LOGIN.LOGIN_BY_LOGIN_ID === modeLogin && !loginId.includes('@')) email = `${loginId}@${DOMAIN_TRANSCOSMOS}`;
  return email;
};

const getUser = async () => {
  const result = await request({
    method: 'get',
    url: '/dashboard/users/me'
  });
  return result;
};

const changePassword = async (oldPassword, newPassword, action) => {
  const result = await request({
    method: 'post',
    url: '/dashboard/users/pwdChange',
    data: {
      oldPassword,
      newPassword,
      action
    }
  });
  return result;
};

const ableToRequestUnlock = async loginId => {
  loginId = getFullEmail(loginId);
  const result = await request(
    {
      method: 'post',
      url: '/dashboard/users/ableToRequestUnlock',
      data: {
        loginId,
        lng: localStorage.getItem('sysLanguage') || 'en_US'
      }
    },
    false,
    false
  );
  return result;
};

const requestUnlockAccount = async loginId => {
  loginId = getFullEmail(loginId);
  const result = await request(
    {
      method: 'post',
      url: '/dashboard/users/unlockRequest',
      data: {
        loginId,
        lng: localStorage.getItem('sysLanguage') || 'en_US'
      }
    },
    false,
    false
  );
  return result;
};

const updateUserProfile = async data => {
  data.lng = localStorage.getItem('sysLanguage') || 'en_US';
  const result = await request({
    method: 'post',
    url: '/dashboard/users',
    data: data
  });
  return result;
};

const ableToResetPassword = async loginId => {
  loginId = getFullEmail(loginId);
  const result = await request(
    {
      method: 'post',
      url: '/dashboard/users/ableToResetPassword',
      data: {
        loginId,
        lng: localStorage.getItem('sysLanguage') || 'en_US'
      }
    },
    false,
    false
  );
  return result;
};

const resetPassword = async loginId => {
  loginId = getFullEmail(loginId);
  const result = await request(
    {
      method: 'post',
      url: '/dashboard/users/resetPassword',
      data: {
        loginId,
        lng: localStorage.getItem('sysLanguage') || 'en_US'
      }
    },
    false,
    false
  );
  return result;
};

const receiveOtpByEmail = async (loginId, action) => {
  loginId = getFullEmail(loginId);
  const result = await request(
    {
      method: 'post',
      url: '/dashboard/users/receiveOtpByEmail',
      data: {
        loginId,
        action,
        lng: localStorage.getItem('sysLanguage') || 'en_US'
      }
    },
    false,
    false
  );
  return result;
};

const verifyOTPChangeMFA = async params => {
  const loginId = getFullEmail(params.loginId);
  const result = await request({
    method: 'post',
    url: '/dashboard/users/verifyOTPChangeMFA',
    data: { ...params, loginId }
  });
  return result;
};

const updateAuthenticatorConfig = async ({ authenticatorSecret, otp }) => {
  const result = await request({
    method: 'post',
    url: '/dashboard/users/updateAuthenticatorConfig',
    data: { authenticatorSecret, otp }
  });
  return result;
};

const generateTwoStepAuthenticationOTP = async params => {
  const loginId = getFullEmail(params.loginId);
  const data = await request({
    method: 'post',
    url: '/dashboard/users/generateTwoStepAuthenticationOTP',
    data: {
      ...params,
      loginId
    }
  });
  return data;
};

const updateTwoStepAuthenticationInfo = async params => {
  const loginId = getFullEmail(params.loginId);
  const data = await request({
    method: 'post',
    url: '/dashboard/users/updateTwoStepAuthenticationInfo',
    data: {
      ...params,
      loginId
    }
  });
  return data;
};

const generateTokenAliasAccount = async params => {
  const data = await request({
    method: 'post',
    url: '/dashboard/users/genarate-token-account',
    data: params
  });
  return data;
};

const getDelegatedProfiles = async () => {
  const data = await request({
    method: 'get',
    url: '/dashboard/users/delegated-profiles'
  });
  return data;
};

const getServiceByDelegatedProfileId = async delegatedProfileId => {
  const data = await request({
    method: 'get',
    url: '/dashboard/users/delegated-profile-services/' + delegatedProfileId
  });
  return data;
};

const removeUserToken = async token => {
  return request(
    {
      method: 'post',
      url: '/dashboard/users/removeUserToken',
      data: { token }
    },
    false,
    false
  );
};

const decryptToken = async token => {
  const data = await request({
    method: 'post',
    url: '/dashboard/auth/decryptToken',
    data: { token }
  });
  return data;
};

export default {
  getUser,
  changePassword,
  ableToRequestUnlock,
  requestUnlockAccount,
  updateUserProfile,
  ableToResetPassword,
  resetPassword,
  receiveOtpByEmail,
  verifyOTPChangeMFA,
  updateAuthenticatorConfig,
  generateTwoStepAuthenticationOTP,
  updateTwoStepAuthenticationInfo,
  generateTokenAliasAccount,
  getDelegatedProfiles,
  getServiceByDelegatedProfileId,
  removeUserToken,
  decryptToken
};
