import view from './view';
import { connect } from 'react-redux';
import { getUser } from 'store/userSlice';
import { selectFullLoading, updateFullLoading } from 'store/loadingSlice';
import { updateNotification } from 'store/notificationSlice';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state),
    currentUser: getUser(state)
  };
};

const mapDispatch = {
  updateFullLoading,
  updateNotification
};

export default connect(mapState, mapDispatch)(view);
