import QRCode from 'qrcode';
import tinyurl from 'tinyurl';
import { Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import t05En from 'assets/img/passwordless/EN/T05_EN.png';
import t05Jp from 'assets/img/passwordless/JP/T05_JP.png';
import t05Tw from 'assets/img/passwordless/TW/T05_TW.png';
import t06En from 'assets/img/passwordless/EN/T06_EN.png';
import t06Jp from 'assets/img/passwordless/JP/T06_JP.png';
import t06Tw from 'assets/img/passwordless/TW/T06_TW.png';
import t07En from 'assets/img/passwordless/EN/T07_EN.png';
import t07Jp from 'assets/img/passwordless/JP/T07_JP.png';
import t07Tw from 'assets/img/passwordless/TW/T07_TW.png';
import loading from 'assets/img/passwordless/loading.gif';
import appleEn from 'assets/images/apps/apple-store-badge.png';
import badgeGoogle from 'assets/images/apps/QRLogo_Android.png';
import appleJp from 'assets/img/passwordless/JP/apple_store_JP.png';
import appleTw from 'assets/img/passwordless/TW/apple_store_TW.png';
import { auth as authService, passwordless } from 'services';
import { logger } from 'core/logger';
import { toast } from 'react-toastify';
import { Confirm } from 'components';
import { Loading } from 'element-react';
import { getBrowserInfo, getCurrentTimestamp } from 'core/helpers';

const StepDownload = ({ handleNextStep }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: appleEn,
    ja_JP: appleJp,
    zh_TW: appleTw
  };

  const [qrCodeAndroid, setQrCodeAndroid] = useState('');
  const [qrCodeIOS, setQrCodeIOS] = useState('');

  const getQRCode = async () => {
    try {
      const result = await authService.getAppDownloadLink();
      if (result.iOS) {
        const iOSURL = await QRCode.toDataURL(result.iOS);
        setQrCodeIOS(iOSURL);
      }
      if (result.android) {
        const shortAndroidURl = await tinyurl.shorten(result.android);
        const androidURL = await QRCode.toDataURL(shortAndroidURl, { rendererOpts: { quality: 1 } });
        setQrCodeAndroid(androidURL);
      }
    } catch (error) {
      console.error('Error fetching QR code:', error);
    }
  };

  useEffect(() => {
    getQRCode();
  }, []);

  return (
    <>
      <Row>
        <Col md={12} className="text-color-sub">
          <p>{t('common.step.passwordless.step1.sub')}</p>
          <p>{t('common.step.passwordless.step1.sub1')}</p>
          <p>{t('common.step.passwordless.step1.sub2')}</p>
          <p>{t('common.step.passwordless.step1.sub3')}</p>
        </Col>
      </Row>
      <Row style={{ width: 740 }} className="m-w-100">
        <Col md={4} className="text-center">
          <img src={imageSources[lang]} height="50px" alt={t('mfa.download.apple')} style={{ width: '65%' }} />
          {qrCodeIOS ? (
            <img src={qrCodeIOS} width="100%" alt={t('mfa.download.apple.qr')} />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '75px',
                paddingBottom: '75px'
              }}
            >
              <img src={loading} alt={t('mfa.download.apple.qr')} />
            </div>
          )}
        </Col>
        <Col md={4} className="text-center">
          <img src={badgeGoogle} style={{ borderRadius: '9px' }} height="50px" alt={t('mfa.download.google')} />
          {qrCodeAndroid ? (
            <img src={qrCodeAndroid} width="101%" alt={t('mfa.download.google.qr')} />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '75px',
                paddingBottom: '75px'
              }}
            >
              <img src={loading} alt={t('mfa.download.google.qr')} />
            </div>
          )}
        </Col>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Col md="10" sm="12" className="text-color-sub text-left">
          {t('common.step.passwordless.step1.explainNext')}
        </Col>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Button color="primary" className="m-l-5" onClick={handleNextStep}>
          {t('common.button.next')}
        </Button>
      </Row>
    </>
  );
};

const StepInstallation = ({ handleNextStep, handlePreviousStep, handleClose }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: t05En,
    ja_JP: t05Jp,
    zh_TW: t05Tw
  };
  return (
    <>
      {/* <Row>
        <Col md={4} className="text-center">
          <p className="text-color-sub mt-4">{t('common.step.passwordless.step2.iphone')}</p>
          <img src={require('assets/img/example.png')} width="100%" alt={t('common.step.passwordless.step2.iphone')} />
        </Col>
        <Col md={4} className="text-center">
          <p className="text-color-sub mt-4">{t('common.step.passwordless.step2.android')}</p>
          <img src={require('assets/img/example.png')} width="100%" alt={t('common.step.passwordless.step2.android')} />
        </Col>
      </Row> */}
      <Row className="mt-4">
        <Col md={12}>
          <img src={imageSources[lang]} alt={t('common.step.passwordless.step2')} style={{ width: '77%' }} />
        </Col>
      </Row>
      <Row>
        <Button color="default" className="m-t-20" onClick={handleClose}>
          {t('common.button.close')}
        </Button>
        <Button color="default" className="m-t-20 m-l-5" onClick={handlePreviousStep}>
          {t('common.label.prev')}
        </Button>
        <Button color="primary" className="m-t-20 m-l-5" onClick={handleNextStep}>
          {t('common.button.next')}
        </Button>
      </Row>
    </>
  );
};

const StepRegistration = ({ handleNextStep, handlePreviousStep, handleClose }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: t06En,
    ja_JP: t06Jp,
    zh_TW: t06Tw
  };
  return (
    <>
      <Row className="mt-4">
        <Col md={12}>
          <img src={imageSources[lang]} alt={t('common.step.passwordless.step3')} style={{ width: '77%' }} />
        </Col>
      </Row>
      <Row>
        <Button color="default" className="m-t-20" onClick={handleClose}>
          {t('common.button.close')}
        </Button>
        <Button color="default" className="m-t-20 m-l-5" onClick={handlePreviousStep}>
          {t('common.label.prev')}
        </Button>
        <Button color="primary" className="m-t-20 m-l-5" onClick={handleNextStep}>
          {t('common.button.next')}
        </Button>
      </Row>
    </>
  );
};

const StepAuthentication = ({ handlePreviousStep, handleClose }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: t07En,
    ja_JP: t07Jp,
    zh_TW: t07Tw
  };
  return (
    <>
      <Row className="m-w-100 mt-4">
        <Col md={12}>
          <img src={imageSources[lang]} alt={t('common.step.passwordless.step4')} style={{ width: '77%' }} />
        </Col>
      </Row>
      <Row>
        <Button color="default" className="m-t-20" onClick={handlePreviousStep}>
          {t('common.label.prev')}
        </Button>
        <Button color="primary" className="m-t-20 m-l-5" onClick={handleClose}>
          {t('common.button.close')}
        </Button>
      </Row>
    </>
  );
};

const PasswordlessSetting = props => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [didUserRegisterPwdless, setDidUserRegisterPwdless] = useState(false);
  const [onShowPwdlInstruction, setOnShowPwdlInstruction] = useState(false);
  const { currentUser: user, updateFullLoading, fullloading, updateNotification } = props;

  const checkUserRegisteredPasswordless = async () => {
    const checking = await passwordless.checkUserRegisteredPasswordless(user.id, user.loginId);
    if (checking && checking.result) {
      setDidUserRegisterPwdless(true);
    } else {
      setDidUserRegisterPwdless(false);
    }
  };

  const handleNextStep = () => setStep(prev => prev + 1);
  const handlePreviousStep = () => setStep(prev => prev - 1);
  const handleClose = () => setStep(1);
  const renderStep = () => {
    switch (step) {
      case 2: {
        return (
          <StepInstallation
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            handleClose={handleClose}
          />
        );
      }
      case 3: {
        return (
          <StepRegistration
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            handleClose={handleClose}
          />
        );
      }
      case 4: {
        return <StepAuthentication handlePreviousStep={handlePreviousStep} handleClose={handleClose} />;
      }
      default: {
        return <StepDownload handleNextStep={handleNextStep} />;
      }
    }
  };

  const writeLogNetworkError = async (funcName, title, user, error) => {
    const timestamp = getCurrentTimestamp();
    const { browserName: model, browserVersion: app_ver, osVersion: os_ver } = getBrowserInfo();

    const errResponse = error.response
      ? error.response.data
      : {
          code: 'network-error',
          message: 'Network Error'
        };
    const errLog = {
      httpStatusCode: 500,
      errorMessage: {
        title: title,
        ...errResponse
      }
    };
    const message = {
      funcName: funcName,
      code: 'network-error'
    };
    await passwordless.writeAuthAppErrorLog(
      timestamp,
      user.loginId,
      user.loginId,
      model,
      os_ver,
      app_ver,
      'ERROR',
      JSON.stringify(message),
      JSON.stringify(errLog)
    );
  };
  const handleDeletePwdlessCredential = async () => {
    updateNotification({
      showModal: true,
      heading: t('common.message.confirm.header'),
      noCancel: false,
      displayText: t('passwordless.popup.delete.registerd.credential.message'),
      type: 'confirm',
      buttonOkText: t('common.button.yes'),
      buttonCloseText: t('common.button.no'),
      callback: async closeModal => {
        closeModal();
        const { browserName: model, browserVersion: app_ver, osVersion: os_ver } = getBrowserInfo();
        const timestamp = getCurrentTimestamp();
        try {
          updateFullLoading(true);
          const checking = await passwordless.checkUserRegisteredPasswordless(user.id, user.loginId);
          const isValidPwdlessRegistration = checking ? checking.result : false;

          if (isValidPwdlessRegistration) {
            const deleting = await passwordless.deleteUserRegisteredPasswordless(user.id, user.loginId);
            if (deleting && deleting.result) {
              toast.success(t('passwordless.delete.user.credential.successfully'));
              setDidUserRegisterPwdless(false);
              setOnShowPwdlInstruction(false);
            }
          } else {
            toast.warning(t('common.message.item.not.found', { label: t('passwordless.registration') }));
            const errLog = {
              httpStatusCode: 404,
              errorMessage: {
                title: t('common.message.item.not.found', { label: t('passwordless.registration') })
              }
            };
            const message = {
              funcName: 'checkUserRegisteredPasswordless',
              code: 'item-not-found'
            };
            await passwordless.writeAuthAppErrorLog(
              timestamp,
              user.loginId,
              user.loginId,
              model,
              os_ver,
              app_ver,
              'ERROR',
              JSON.stringify(message),
              JSON.stringify(errLog)
            );
          }
        } catch (error) {
          const title = t('passwordless.delete.user.credential.failed');
          await writeLogNetworkError('deleteUserRegisteredPasswordless', title, user, error);
          logger.error('handleDeletePwdlessCredential -> err', error);
          toast.warning(title);
        } finally {
          updateFullLoading(false);
        }
      }
    });
  };
  const handleClickOnRegisterButton = async () => {
    try {
      updateFullLoading(true);
      await checkUserRegisteredPasswordless();
      if (didUserRegisterPwdless) {
        setOnShowPwdlInstruction(false);
      } else {
        setOnShowPwdlInstruction(true);
      }
    } catch (error) {
      logger.error('checkUserPwdlessRegistration -> err', error);
      const title = t('passwordless.get.user.credential.failed');
      toast.warning(title);
      await writeLogNetworkError('checkUserRegisteredPasswordless', title, user, error);
    } finally {
      updateFullLoading(false);
    }
  };

  useEffect(() => {
    const fetchPwdlessRegistration = async () => {
      try {
        await checkUserRegisteredPasswordless();
      } catch (error) {
        logger.error('checkUserPwdlessRegistration -> err', error);
        const title = t('passwordless.get.user.credential.failed');
        toast.warning(title);
        await writeLogNetworkError('checkUserRegisteredPasswordless', title, user, error);
      }
    };
    fetchPwdlessRegistration();
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
        {didUserRegisterPwdless && (
          <>
            <div id="delete-pwdless-box">
              <div id="delete-pwdless-box-btn">
                <Button
                  color="primary"
                  id="delete-pwdless-btn"
                  className="m-r-10"
                  onClick={handleDeletePwdlessCredential}
                >
                  {t('common.button.reset')}
                </Button>
              </div>
              <div id="delete-pwdless-box-text">
                <p className="delete-pwdless-box-text-content">{t('passwordless.delete.credential.info.1')}</p>
                <p className="delete-pwdless-box-text-content">{t('passwordless.delete.credential.info.2')}</p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h2>
                {t('common.step.passwordless', { header: t(`common.step.passwordless.step${step}`), step, total: 4 })}
              </h2>
            </div>
            <div>{renderStep()}</div>
          </>
        )}
        {!didUserRegisterPwdless && onShowPwdlInstruction && (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h2>
                {t('common.step.passwordless', { header: t(`common.step.passwordless.step${step}`), step, total: 4 })}
              </h2>
            </div>
            <div>{renderStep()}</div>
          </>
        )}
        {!didUserRegisterPwdless && !onShowPwdlInstruction && (
          <div id="register-pwdless-box">
            <Button color="info" id="register-pwdless-btn" className="m-r-10" onClick={handleClickOnRegisterButton}>
              {t('passwordless.register.credential.btn')}
            </Button>
            <p className="register-pwdless-text">{t('passwordless.register.credential.info')}</p>
          </div>
        )}
      </div>
      {fullloading && <Loading fullscreen={true} />}
    </div>
  );
};

export default PasswordlessSetting;
