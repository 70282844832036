import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardTable } from 'components';
import { toast } from 'react-toastify';
import { resource as resourceService, user as userService } from 'services';
import { cookies } from 'core/utils';
import { COOKIE_KEY_USER_TOKEN, SERVICE_PROVIDER, delegatedProfileTypes } from 'core/constants';
import { errorUtils } from 'core/utils';
import { logger } from 'core/logger';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import defaultIcon from 'assets/images/resource/icon.png';
import './index.scss';

class Paragraph extends React.Component {
  static propTypes = { content: PropTypes.string };
  static defaultProps = { content: '' };
  render = () => <p className={''} dangerouslySetInnerHTML={{ __html: this.props.content }} />;
}

const spin = (
  <div className="loadingTable">
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

function SectionList(props) {
  const { t } = useTranslation();
  const { resources, initialized = true, updateNotification, updateFullLoading, updateUser, history } = props;
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedResourceToAccess, setSelectedResourceToAccess] = useState(null);
  const [showAliasAccountsModal, setShowAliasAccountsModal] = useState(false);
  const [accountDelegateds, setAccountDelegateds] = useState([]);
  const onSelectResources = id => {
    let list = selectedResources;
    if (list.includes(id)) {
      list = selectedResources.filter(_id => _id !== id);
    } else {
      list = [...list, id];
    }
    setSelectedResources(list);
  };

  const onClickResources = async (item, delegatedProfile = null) => {
    try {
      const idpid = item && item.samlresources && item.samlresources.idpid ? item.samlresources.idpid : '';
      const { id, action, serviceProviderType, environment } = item;
      updateFullLoading(true);
      if (action !== 2) {
        return;
      }
      await resourceService.verifyPolicy(id);
      const { id: delegatedProfileId, type = '', loginId = '', alias = '' } = delegatedProfile || {};

      let userToken = cookies.getKey(COOKIE_KEY_USER_TOKEN);
      const userInfo = props.userInfo;
      const timezoneBrowser = moment.tz.guess();
      let isLoginAlias = false;
      if (type === delegatedProfileTypes.SHARED_LOGIN_ID) {
        const params = {
          userId: userInfo.id,
          loginId: loginId,
          timezoneBrowser
        };
        const getUserTokenByDelegatedProfile = await userService.generateTokenAliasAccount(params);
        userToken = getUserTokenByDelegatedProfile.userToken;
        isLoginAlias = true;
      } else if (type === delegatedProfileTypes.SHARED_EMAIL_ALIAS) {
        const { parentLoginId } = userInfo;
        let userLoginId = parentLoginId || userInfo.loginId;
        // get account's domain
        const accountDomain = userLoginId && userLoginId.split('@')[1] ? userLoginId.split('@')[1].toLowerCase() : null;
        // get account's domain
        const accountName = userLoginId && userLoginId.split('@')[0] ? userLoginId.split('@')[0].toLowerCase() : null;
        const loginId = `${accountName}+${alias}@${accountDomain}`;
        const params = {
          userId: userInfo.id,
          loginId: loginId,
          timezoneBrowser
        };
        const getUserTokenByDelegatedProfile = await userService.generateTokenAliasAccount(params);
        userToken = getUserTokenByDelegatedProfile.userToken;
        isLoginAlias = true;
      } else if (type === delegatedProfileTypes.SHARED_LYSITHEAID_ID) {
        const params = {
          userId: userInfo.id,
          loginId: userInfo.parentLoginId || userInfo.loginId,
          delegatedId: type === '' ? null : delegatedProfileId,
          timezoneBrowser
        };
        const getUserTokenByDelegatedProfile = await userService.generateTokenAliasAccount(params);
        userToken = getUserTokenByDelegatedProfile.userToken;
        isLoginAlias = true;
      }
      if (SERVICE_PROVIDER.MICROSOFT_365_AZURE === serviceProviderType) {
        let delegatedId = isLoginAlias ? delegatedProfileId : null;
        try {
          const url = await resourceService.microsoftAuth(id, delegatedId);
          return url;
        } catch (err) {
          logger.error('goToResource -> err', err);
          toast.warning(t('ServiceProvider.M365.Message.Error'));
          updateFullLoading(false);
        }
      } else if (idpid) {
        const allowedRedirectExceptions = process.env.REACT_APP_SAML_ALLOWED_REDIRECT_EXCEPTIONS || '';
        const data = await resourceService.getInitSaml(idpid, isLoginAlias ? delegatedProfileId : '');
        const { context, entityEndpoint } = data;

        if (allowedRedirectExceptions.indexOf(idpid) > -1) {
          return { context, entityEndpoint, delegatedProfileId };
        }

        const form = document.createElement('form');
        form.setAttribute('action', entityEndpoint);
        form.setAttribute('method', 'POST');
        form.setAttribute('id', 'saml-form');
        form.setAttribute('target', '_blank');
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('id', 'SAMLResponse');
        input.setAttribute('name', 'SAMLResponse');
        input.setAttribute('dpid', delegatedProfileId);

        input.setAttribute('value', context);
        form.appendChild(input);
        document.body.appendChild(form);
        document.getElementById('saml-form').submit();
        document.body.removeChild(form);
      } else if (['lysithea_pc', 'lysithea_sp', 'time_sheet'].includes(serviceProviderType)) {
        let url =
          'PREVIEW' === environment
            ? serviceProviderType === 'lysithea_pc'
              ? process.env.REACT_APP_PREVIEW_LYSITHEA_PC_URL
              : serviceProviderType === 'lysithea_sp'
              ? process.env.REACT_APP_PREVIEW_LYSITHEA_SP_URL
              : process.env.REACT_APP_PREVIEW_LYSITHEA_TIME_SHEET_URL
            : serviceProviderType === 'lysithea_pc'
            ? process.env.REACT_APP_LYSITHEA_PC_URL
            : serviceProviderType === 'lysithea_sp'
            ? process.env.REACT_APP_LYSITHEA_SP_URL
            : process.env.REACT_APP_LYSITHEA_TIME_SHEET_URL;
        // call API to decrypt user token
        const decryptTokenRequest = await userService.decryptToken(userToken);
        const decryptToken = decryptTokenRequest.token;
        // create url
        if (['lysithea_pc', 'lysithea_sp'].includes(serviceProviderType)) {
          url = `${url}&token=${decryptToken}`;
        } else if ('time_sheet' === serviceProviderType) {
          url = `${url}?token=${decryptToken}`;
        }
        // redirect to url
        return url;
      }
    } catch (err) {
      logger.error('goToResource -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (['resource-not-found', 'permission_denied'].includes(code)) {
        toast.warning(t('common.message.resource.notFound2'));
      } else if (code === 'out-of-working-hours') {
        toast.warning(t('common.message.policy.outOfWorkingHour'));
      } else if (code === 'resource-is-maintenance') {
        toast.warning(t('label.resource.isMaintenance'));
      } else if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else if (code === 'invalid-user-account') {
        toast.warning(t('Service.UserAccount.Error.AliasLengthTooLong'));
      } else if (code === 'invalid-email-format') {
        toast.warning(t('common.validator.invalidEmail'));
      } else {
        errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const onCardClick = async item => {
    /*
    🚀 PAY ATTENTION PLEASE 🚀
    REGARDING THE ISSUE POPUP-BLOCKER ON SAFARI BROWSER
    WHAT DOESN'T ALLOW US TO OPEN A POPUP BY EXECUTE WINDOW.OPEN()
    PLEASE DON'T PUT ANY ASYNC FUNCTION IN THIS CODE BLOCK
    PLEASE ALSO CONTACT TECHNICAL LEADER AND RELATIVES IF YOU WANT TO UPDATE THIS FUNCTION AS WELL
    */
    const { samlresources, delegatedProfiles } = item;
    if (samlresources) {
      const { actionType, url } = samlresources || {};
      if (actionType === 'URL') {
        window.open(url);
        return;
      }
    }
    try {
      setSelectedResourceToAccess(item);
      setAccountDelegateds(delegatedProfiles);
      if (delegatedProfiles && delegatedProfiles.length) {
        setShowAliasAccountsModal(true);
      } else {
        await handleRedirectToResource(item);
      }
    } catch (err) {
      errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
    }
  };

  const redirectToResourceByAliasAccount = async delegateAccount => {
    setShowAliasAccountsModal(false);
    await handleRedirectToResource(selectedResourceToAccess, delegateAccount);
  };

  const handleRedirectToResource = async (item, delegateAccount) => {
    const { serviceProviderType, samlresources = {} } = item;
    const { idpid = '' } = samlresources;
    const allowedRedirectExceptions = process.env.REACT_APP_SAML_ALLOWED_REDIRECT_EXCEPTIONS || '';
    const allowRedirect = [
      SERVICE_PROVIDER.LYSITHEA_PC,
      SERVICE_PROVIDER.LYSITHEA_SP,
      SERVICE_PROVIDER.TIME_SHEET,
      SERVICE_PROVIDER.MICROSOFT_365_AZURE
    ].includes(serviceProviderType);
    const allowRedirectSAML =
      SERVICE_PROVIDER.SAML_CUSTOM === serviceProviderType && allowedRedirectExceptions.indexOf(idpid) > -1;
    let form = {};
    let _windowReference = allowRedirect ? window.open() : allowRedirectSAML ? window.open('', 'FormSAML') : null;
    await onClickResources(item, delegateAccount).then(url => {
      if (allowRedirectSAML) {
        form = url;
      } else {
        if (url && url !== '' && allowRedirect) {
          _windowReference.location = url;
          _windowReference.opener = null;
          _windowReference.parent = null;
          _windowReference.rel = 'noreferrer';
        } else if (_windowReference) _windowReference.close();
      }
    });

    if (allowRedirectSAML) {
      openSamlForm(form);
    }
  };

  const openSamlForm = ({ entityEndpoint, context, delegatedProfileId }) => {
    const form = document.createElement('form');
    form.setAttribute('action', entityEndpoint);
    form.setAttribute('method', 'POST');
    form.setAttribute('id', 'saml-form');
    form.setAttribute('target', 'FormSAML');
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('id', 'SAMLResponse');
    input.setAttribute('name', 'SAMLResponse');
    input.setAttribute('dpid', delegatedProfileId);

    input.setAttribute('value', context);
    form.appendChild(input);
    document.body.appendChild(form);
    document.getElementById('saml-form').submit();
    document.body.removeChild(form);
  };

  const renderResourceList = () => {
    let { userInfo = {} } = props;
    if (!initialized) {
      return spin;
    }
    let iconURL = null,
      description = null,
      serviceType = null,
      selectedResourceName = null;

    if (selectedResourceToAccess) {
      const { name, serviceProviderType } = selectedResourceToAccess;
      selectedResourceName = name;
      serviceType = serviceProviderType;
      iconURL = selectedResourceToAccess?.iconURL;
      description = selectedResourceToAccess?.description;
    }
    const allowRedirect = [
      SERVICE_PROVIDER.LYSITHEA_PC,
      SERVICE_PROVIDER.LYSITHEA_SP,
      SERVICE_PROVIDER.TIME_SHEET
    ].includes(serviceType);
    let mainLoginId = allowRedirect ? userInfo.lysitheaId : userInfo.loginId;

    if (resources.length) {
      return (
        <>
          <CardTable
            tasks={resources}
            selectedTasks={selectedResources}
            onSelectCard={id => onSelectResources(id)}
            onClickCard={item => onCardClick(item)}
            ableToSelect={false}
            userInfo={userInfo}
          />
          <Modal
            isOpen={showAliasAccountsModal}
            centered
            zIndex={2050}
            modalClassName="modal-choose-alias-accounts"
            size="md"
          >
            <ModalHeader toggle={() => setShowAliasAccountsModal(false)}>
              <img src={iconURL || defaultIcon} alt={description} />
              <span>{t('modal.select.alias')}</span>
            </ModalHeader>
            <ModalBody>
              <div className="modal-description">
                <h3>{t('modal.choose.an.identity')}</h3>
                <Paragraph content={t('modal.continue.to.resource', { resource: selectedResourceName })} />
              </div>
              {accountDelegateds.length && (
                <div key={userInfo.loginId} className="account-select">
                  <Button onClick={() => redirectToResourceByAliasAccount({ type: null })}>
                    <div className={`alias-avatar-item`}>
                      <div>
                        <img
                          className="main-avatar-content"
                          src={
                            userInfo.profileURL ? userInfo.profileURL : require('assets/images/avatars/user-avatar.png')
                          }
                          alt={description}
                        />
                      </div>
                      <div className="login-name">
                        {' '}
                        <span className="title-login">{t('DelegatedProfile.Title.MainAccount')}</span>
                        <span className="limit-length">{mainLoginId}</span>
                      </div>
                    </div>
                  </Button>
                </div>
              )}

              {accountDelegateds.map(({ id, type, alias, loginId, lysitheaId }) => {
                let nameLogin = '';
                let srcImg = '';
                let title = '';
                switch (type) {
                  case delegatedProfileTypes.SHARED_EMAIL_ALIAS:
                    nameLogin = alias;
                    srcImg = require('assets/images/providers/alias.svg');
                    title = 'DelegatedProfile.Title.Alias';
                    break;
                  case delegatedProfileTypes.SHARED_LOGIN_ID:
                    nameLogin = loginId;
                    srcImg = require('assets/images/providers/loginId.svg');
                    title = 'DelegatedProfile.Title.LoginID';
                    break;
                  default:
                    nameLogin = lysitheaId;
                    srcImg = require('assets/images/providers/IDType.Lysithea.png');
                    title = 'DelegatedProfile.Title.LysitheaID';
                    break;
                }
                return (
                  <div key={nameLogin} className="account-select">
                    <Button onClick={() => redirectToResourceByAliasAccount({ id, type, alias, loginId, lysitheaId })}>
                      <div className={`alias-avatar-item`}>
                        <div
                          className={
                            type === delegatedProfileTypes.SHARED_LYSITHEAID_ID
                              ? 'avatar-account bg-color-lysithea'
                              : 'avatar-account bg-color-alias-login'
                          }
                        >
                          <img
                            style={{ filter: 'invent(1)' }}
                            className={
                              type === delegatedProfileTypes.SHARED_LYSITHEAID_ID
                                ? 'avatar-content'
                                : 'avatar-alias-login'
                            }
                            src={srcImg}
                            alt={description}
                          />
                        </div>
                        <div className="login-name">
                          {' '}
                          <span className="title-login">{t(title)}</span>
                          <span className="limit-length">{nameLogin}</span>
                        </div>
                      </div>
                    </Button>
                  </div>
                );
              })}
            </ModalBody>
          </Modal>
        </>
      );
    } else {
      return (
        <div className="p-b-20 m-auto text-center text-muted d-block">
          <span className="material-icons md-36">error_outline</span>
          <div className="d-block mt-1">{t('common.label.no.services')}</div>
        </div>
      );
    }
  };

  return <div className="resource-list d-flex flex-wrap p-b-0">{renderResourceList()}</div>;
}

export default SectionList;
